import { DateTime } from 'luxon'
import { HourMinute, TimeRange } from '../types/timeRange'

export function asHours(hourMinute: HourMinute) {
  const hours = parseInt(hourMinute.slice(0, 2))
  const minutes = parseInt(hourMinute.slice(3, 5))
  return hours + minutes / 60
}

export function timeRangeDurationInHours(timeRange: TimeRange) {
  const { start, end } = timeRange

  const startAsHours = asHours(start)
  const endAsHours = asHours(end)

  // test depends on whether range intersects midnight
  if (end <= start) return 24 + endAsHours - startAsHours
  return endAsHours - startAsHours
}

export function getPreviousDates(date: string, nbDays: number) {
  const end = DateTime.fromISO(date)

  return Array(nbDays)
    .fill(undefined)
    .map((_, index) => nbDays - index - 1)
    .map((days) => end.minus({ days }).toISODate())
}

// 🌪️ i18n warning : update accordinly in shared/src/utils/timeRange.i18n.ts 🌪️
export function timeRangeString(timeRange: TimeRange | undefined) {
  return timeRange === undefined || timeRange.start === timeRange.end
    ? '24h/24'
    : `de ${timeRange.start} à ${timeRange.end}`
}

export function isWithinTimeRange(dateTime: DateTime, timeRange: TimeRange) {
  const { start, end } = timeRange

  // continuous monitoring
  if (end === start) return true

  const hh_mm = dateTime.setZone('Europe/Paris').toFormat('HH:mm')

  // test depends on whether range intersects midnight
  if (end < start) return hh_mm >= start || hh_mm < end
  return hh_mm >= start && hh_mm < end
}

// True IIF child is entirely included in parent
export function isRangeContainedWithin(child: TimeRange, parent: TimeRange) {
  // parent: 24/24
  if (parent.end === parent.start) return true

  // parent: not 24/24
  // enfant: 24/24
  if (child.end === child.start) return false

  // parent: heure de début J+0, heure de fin J+0
  if (parent.start < parent.end)
    // enfant: heure de début et fin J+0 et inclus
    return (
      child.start < child.end &&
      child.start >= parent.start &&
      child.end <= parent.end
    )

  // parent: heure de début J+0, heure de fin J+1
  if (child.start > child.end)
    // enfant: heure de début J+0, heure de fin J+1
    // must start after and end before
    return child.start >= parent.start && child.end <= parent.end

  // enfant: à J+0 après début, ou à J+1 avant fin
  return child.start >= parent.start || child.end <= parent.end
}
