import { LabelsPagination, OntologyType } from './types'

const LABELS_KEYS = [
  'abattant_wc',
  'activite_as',
  'activite_humaine',
  'activite_resident',
  'aerosol',
  'aerosol_en_cours',
  'aerosol_en_fin_de_dose',
  'aerosol_vide',
  'agitation',
  'alarme',
  'animaux',
  'appel',
  'apnee',
  'arret_aerosol',
  'aspirateur_menager',
  'aspirateur_a_mucosites',
  'aucun_son',
  'baillement',
  'barriere_du_lit',
  'bassine',
  'bouilloire',
  'bequille_canne_deambulateur',
  'bip_machine',
  'bruit_de_pas',
  'bruit_de_pas_as',
  'bruit_de_pas_resident',
  'bruit_humain',
  'brosse_a_dent_electrique',
  'cafetiere',
  'canule_bouchee',
  'chant',
  'chariot',
  'chasse_eau',
  'chat',
  'chien',
  'choc',
  'choc_leger',
  'choc_violent',
  'choc_fort',
  'chuchotement',
  'chute',
  'chute_de_personne',
  'clap_mains',
  'claquement',
  'clicker_clochette_xl',
  'clicker_clochette_mini',
  'conversation',
  'conversation_as_as',
  'conversation_resident_as',
  'conversation_resident_resident',
  'conversation_telephonique',
  'cornage',
  'crachat',
  'cri',
  'cri_de_bebe',
  'defecation',
  'deglutition',
  'deux_personnes_ou_plus',
  'difficulte_respiratoire',
  'douche',
  'draps',
  'eau',
  'electromenager',
  'emotions',
  'essoufflement',
  'eternuement',
  'expectoration',
  'expiration',
  'expiration_freinee',
  'exterieur',
  'fermeture_eclair',
  'feu_artifice',
  'flatulence',
  'frigo',
  'frottement',
  'fuite_aerosol',
  'fuites_au_masque',
  'gemissement',
  'gemissement_expiratoire',
  'grincement',
  'grincement_de_dent',
  'grincement_lit',
  'grincement_porte',
  'grincement_volet',
  'grognement',
  'hoquet',
  'horloge',
  'imprimante',
  'inaudible',
  'inspiration',
  'interpretation_globale',
  'interrupteur',
  'joyeux',
  'langue_etrangere',
  'leve_personne',
  'lit',
  'lit_moteur',
  'machine_a_laver',
  'machines',
  'machine_medicale',
  'marmonnement',
  'mastication',
  'matelas_anti_escarres',
  'meubles_deplaces_au_sol',
  'micro_ondes',
  'miction_urine',
  'mouchage',
  'necessite_de_prevenir_as',
  'o2_fuite_au_masque',
  'o2_haut_debit',
  'o2_normal',
  'objet_qui_tombe',
  'obstruction_nasale',
  'oiseaux',
  'oxygenotherapie',
  'parole_loin',
  'parole_resident_intelligible',
  'pleur_sanglot',
  'pluie',
  'polypnée',
  'pompe_nutrition_enterale',
  'porte',
  'porte_coulissante_tiroir',
  'porte_electro_menager',
  'porte_entree',
  'porte_placard',
  'raclement_gorge',
  'rail',
  'rale_expiratoire',
  'rasoir_electrique',
  'rasoir_tondeuse_electrique',
  'reniflement',
  'resident_calme',
  'resident_endormi',
  'resident_eveille',
  'resident_stresse',
  'respiration',
  'respiration_a_surveiller',
  'respiration_anormalement_rapide',
  'respiration_asthmatiforme',
  'respiration_eau_dans_les_poumons',
  'respiration_encombree',
  'respiration_informative',
  'respiration_levres_pincees',
  'respiration_monotone',
  'respiration_necessitant_intervention_rapide',
  'respiration_sifflante',
  'rideau',
  'rire',
  'robinet',
  'ronflement',
  'ronflement_vocalise',
  'rot',
  'scratch',
  'seche_cheveux',
  'se_sert_de_l_eau',
  'sifflement',
  'sifflement_expiration',
  'sifflement_inspiration',
  'signes_respiratoires',
  'sommeil_eveil',
  'son_lointain',
  'sonnette',
  'soupir',
  'stress',
  'stridor',
  'suspicion_de_souffrance_respiratoire',
  'tambourinage',
  'telephone_sonnerie',
  'telephone_vibreur',
  'television',
  'toc_toc',
  'tonnerre',
  'toux',
  'toux_asthmatiforme',
  'toux_grasse',
  'toux_seche',
  'toux_sifflante',
  'trousseau_de_cle',
  'vaisselle',
  'vehicule',
  'vent',
  'ventilation',
  'vni',
  'vni_en_cours',
  'vocalisation',
  'voix_aidant',
  'voix_as',
  'voix_par_le_telephone',
  'voix_resident',
  'voix_tiers',
  'volet_roulant',
  'vomissement',
  'wheezing',
] as const

export type LabelKey = (typeof LABELS_KEYS)[number]

export const LABEL_TEXT: Record<LabelKey, string> = {
  abattant_wc: 'Abattant WC',
  activite_as: 'Activité AS',
  activite_humaine: 'Activité humaine',
  activite_resident: 'Activité résident',
  aerosol: 'Aérosol',
  aerosol_en_cours: 'Aérosol en cours',
  aerosol_en_fin_de_dose: 'Aérosol en fin de dose',
  aerosol_vide: 'Aérosol vide',
  agitation: 'Agitation',
  alarme: 'Alarme',
  animaux: 'Animaux',
  appel: 'Appel',
  apnee: 'Apnée',
  arret_aerosol: 'Arrêt aérosol',
  aspirateur_menager: 'Aspirateur ménager',
  aspirateur_a_mucosites: 'Aspirateur à mucosités',
  aucun_son: 'Aucun son',
  baillement: 'Bâillement',
  barriere_du_lit: 'Barrière du lit',
  bassine: 'Bassine',
  bequille_canne_deambulateur: 'Béquille / Canne / Déambulateur',
  bouilloire: 'Bouilloire',
  bip_machine: 'Bip (machine)',
  brosse_a_dent_electrique: 'Brosse à dent électrique',
  bruit_de_pas: 'Bruit de pas',
  bruit_de_pas_as: 'Bruit de Pas AS',
  bruit_de_pas_resident: 'Bruit de Pas Résident',
  bruit_humain: 'Bruit humain',
  cafetiere: 'Cafetière',
  canule_bouchee: 'Canule bouchée',
  chant: 'Chant',
  chariot: 'Chariot',
  chasse_eau: "Chasse d'eau",
  chat: 'Chat',
  chien: 'Chien',
  choc: 'Choc',
  choc_leger: 'Choc léger',
  choc_violent: 'Choc violent',
  choc_fort: 'Choc fort',
  chuchotement: 'Chuchotement',
  chute: 'Chute',
  chute_de_personne: 'Chute de personne',
  clap_mains: 'Clap mains',
  claquement: 'Claquement',
  clicker_clochette_xl: 'Clicker clochette XL',
  clicker_clochette_mini: 'Clicker clochette mini',
  conversation: 'Conversation',
  conversation_as_as: 'Conversation AS / AS',
  conversation_resident_as: 'Conversation résident / AS',
  conversation_resident_resident: 'Conversation résident / résident',
  conversation_telephonique: 'Conversation téléphonique',
  cornage: 'Cornage',
  crachat: 'Crachat',
  cri: 'Cri',
  cri_de_bebe: 'Cri de bébé',
  defecation: 'Défécation',
  deglutition: 'Déglutition',
  deux_personnes_ou_plus: 'Deux personnes ou +',
  difficulte_respiratoire: 'Difficulté respiratoire',
  douche: 'Douche',
  draps: 'Draps',
  eau: 'Eau',
  electromenager: 'Electroménager',
  emotions: 'Emotions',
  essoufflement: 'Essoufflement',
  eternuement: 'Eternuement',
  expectoration: 'Expectoration',
  expiration: 'Expiration',
  expiration_freinee: 'Expiration freinée',
  exterieur: 'Extérieur',
  fermeture_eclair: 'Fermeture éclair',
  feu_artifice: "Feu d'artifice",
  flatulence: 'Flatulence',
  frigo: 'Frigo',
  frottement: 'Frottement',
  fuite_aerosol: 'Fuite aérosol',
  fuites_au_masque: 'Fuites au masque',
  gemissement: 'Gémissement',
  gemissement_expiratoire: 'Gémissement expiratoire',
  grincement: 'Grincement',
  grincement_de_dent: 'Grincement de dent',
  grincement_lit: 'Grincement (Lit)',
  grincement_porte: 'Grincement (Porte)',
  grincement_volet: 'Grincement (Volet)',
  grognement: 'Grognement',
  hoquet: 'Hoquet',
  horloge: 'Horloge',
  imprimante: 'Imprimante',
  inaudible: 'Inaudible',
  inspiration: 'Inspiration',
  interpretation_globale: 'Interprétation globale',
  interrupteur: 'Interrupteur',
  joyeux: 'Joyeux',
  langue_etrangere: 'Langue étangère',
  leve_personne: 'Lève personne',
  lit: 'Lit',
  lit_moteur: 'Lit (Moteur)',
  machines: 'Machines',
  machine_medicale: 'Machine médicale',
  machine_a_laver: 'Machine à laver',
  marmonnement: 'Marmonnement',
  mastication: 'Mastication',
  matelas_anti_escarres: 'Matelas anti-escarres',
  meubles_deplaces_au_sol: 'Meubles déplacés au sol',
  micro_ondes: 'Micro-ondes',
  miction_urine: 'Miction (urine)',
  mouchage: 'Mouchage',
  necessite_de_prevenir_as: 'Nécessite de prévenir AS',
  o2_fuite_au_masque: 'O2 fuite au masque',
  o2_haut_debit: 'O2 haut débit',
  o2_normal: 'O2 normal',
  objet_qui_tombe: 'Objet qui tombe',
  obstruction_nasale: 'Obstruction nasale',
  oiseaux: 'Oiseaux',
  oxygenotherapie: 'Oxygénothérapie',
  parole_loin: 'Parole Loin',
  parole_resident_intelligible: 'Parole résident intelligible',
  pleur_sanglot: 'Pleur / Sanglot',
  pluie: 'Pluie',
  polypnée: 'Polypnée',
  pompe_nutrition_enterale: 'Pompe nutriton entérale',
  porte: 'Porte',
  porte_coulissante_tiroir: 'Porte coulissante / Tiroir',
  porte_electro_menager: 'Porte électro-ménager',
  porte_entree: 'Porte entrée',
  porte_placard: 'Porte placard',
  raclement_gorge: 'Raclement gorge',
  rail: 'Rail',
  rale_expiratoire: 'Râle expiratoire',
  rasoir_electrique: 'Rasoir électrique',
  rasoir_tondeuse_electrique: 'Rasoir / tondeuse électrique',
  reniflement: 'Reniflement',
  resident_calme: 'Résident calme',
  resident_endormi: 'Résident endormi',
  resident_eveille: 'Résident éveillé',
  resident_stresse: 'Résident stressé',
  respiration: 'Respiration',
  respiration_a_surveiller: 'Respiration à surveiller',
  respiration_anormalement_rapide: 'Respiration anormalement rapide',
  respiration_asthmatiforme: 'Respiration asthmatiforme',
  respiration_eau_dans_les_poumons: 'Respiration eau dans les poumons',
  respiration_encombree: 'Respiration encombrée',
  respiration_informative: 'Respiration informative',
  respiration_levres_pincees: 'Respiration à lèvres pincées',
  respiration_monotone: 'Respiration monotone',
  respiration_necessitant_intervention_rapide:
    'Respiration nécessitant une intervention rapide',
  respiration_sifflante: 'Respiration sifflante',
  rideau: 'Rideau',
  rire: 'Rire',
  robinet: 'Robinet',
  ronflement: 'Ronflement',
  ronflement_vocalise: 'Ronflement vocalisé',
  rot: 'Rot',
  scratch: 'Scratch',
  se_sert_de_l_eau: "Se sert de l'eau",
  seche_cheveux: 'Sèche-cheveux',
  sifflement: 'Sifflement',
  sifflement_expiration: "Sifflement à l'expiration",
  sifflement_inspiration: "Sifflement à l'inspiration",
  signes_respiratoires: 'Signes respiratoires',
  sommeil_eveil: 'Sommeil / Eveil',
  son_lointain: 'Son Lointain',
  sonnette: 'Sonnette',
  soupir: 'Soupir',
  stress: 'Stress',
  stridor: 'Stridor',
  suspicion_de_souffrance_respiratoire: 'Suspicion de souffrance respiratoire',
  tambourinage: 'Tambourinage',
  telephone_sonnerie: 'Téléphone (sonnerie)',
  telephone_vibreur: 'Téléphone (vibreur)',
  television: 'Télévision',
  toc_toc: 'Toc Toc',
  tonnerre: 'Tonnerre',
  toux: 'Toux',
  toux_asthmatiforme: 'Toux asthmatiforme',
  toux_grasse: 'Toux grasse',
  toux_seche: 'Toux sèche',
  toux_sifflante: 'Toux sifflante',
  trousseau_de_cle: 'Trousseau de clé',
  vaisselle: 'Vaisselle',
  vehicule: 'Véhicule',
  vent: 'Vent',
  ventilation: 'Ventilation',
  vni: 'VNI',
  vni_en_cours: 'VNI en cours',
  vocalisation: 'Vocalisation',
  voix_aidant: 'Voix aidant',
  voix_as: 'Voix AS',
  voix_par_le_telephone: 'Voix par le téléphone',
  voix_resident: 'Voix Résident',
  voix_tiers: 'Voix tiers',
  volet_roulant: 'Volet roulant',
  vomissement: 'Vomissement',
  wheezing: 'Wheezing',
}

export const LABEL_DESCRIPTION: Partial<Record<LabelKey, string>> = {}

const LABELS_PAGINATION: LabelsPagination = [
  [
    {
      title: 'aucun_son',
      type: 'soft',
    },
    {
      title: 'inaudible',
      type: 'soft',
    },
    {
      title: 'voix_aidant',
      type: 'soft',
      subLabels: ['voix_as', 'voix_tiers'],
    },
    {
      title: 'voix_resident',
      type: 'soft',
    },
    {
      title: 'television',
      type: 'soft',
    },
    {
      title: 'voix_par_le_telephone',
      type: 'soft',
    },
    {
      title: 'parole_loin',
      type: 'soft',
    },
    {
      title: 'son_lointain',
      type: 'soft',
    },
  ],
  [
    {
      title: 'clicker_clochette_xl',
      type: 'soft',
    },
    {
      title: 'clicker_clochette_mini',
      type: 'soft',
    },
    {
      title: 'grincement',
      type: 'soft',
      subLabels: ['grincement_lit', 'grincement_porte', 'grincement_volet'],
    },
    {
      title: 'choc',
      type: 'soft',
      subLabels: ['choc_fort', 'choc_leger'],
    },
    {
      title: 'alarme',
      type: 'soft',
      subLabels: ['telephone_sonnerie', 'bip_machine', 'sonnette'],
    },
    {
      title: 'vocalisation',
      type: 'soft',
      subLabels: [
        'gemissement',
        'grognement',
        'marmonnement',
        'chuchotement',
        'pleur_sanglot',
        'chant',
        'rire',
        'cri',
        'cri_de_bebe',
      ],
    },
  ],
  [
    {
      title: 'toc_toc',
      type: 'soft',
    },
    {
      title: 'porte',
      type: 'soft',
      subLabels: [
        'porte_entree',
        'porte_placard',
        'porte_electro_menager',
        'porte_coulissante_tiroir',
        'abattant_wc',
      ],
    },
    {
      title: 'bruit_de_pas',
      type: 'soft',
      subLabels: [
        'bruit_de_pas_as',
        'bruit_de_pas_resident',
        'bequille_canne_deambulateur',
      ],
    },
    {
      title: 'interrupteur',
      type: 'soft',
    },
    {
      title: 'agitation',
      type: 'soft',
      subLabels: ['clap_mains', 'tambourinage', 'meubles_deplaces_au_sol'],
    },
    {
      title: 'bruit_humain',
      type: 'soft',
      subLabels: [
        'mastication',
        'eternuement',
        'sifflement',
        'baillement',
        'deglutition',
        'hoquet',
        'mouchage',
        'raclement_gorge',
        'grincement_de_dent',
        'crachat',
        'flatulence',
        'defecation',
        'miction_urine',
        'rot',
        'vomissement',
      ],
    },
  ],
  [
    {
      title: 'lit',
      type: 'soft',
      subLabels: ['draps', 'barriere_du_lit', 'claquement'],
    },
    {
      title: 'frottement',
      type: 'soft',
    },
    {
      title: 'machine_medicale',
      type: 'soft',
      subLabels: [
        'matelas_anti_escarres',
        'aerosol',
        'vni',
        'aspirateur_a_mucosites',
        'oxygenotherapie',
        'rail',
        'leve_personne',
        'lit_moteur',
        'pompe_nutrition_enterale',
      ],
    },
    {
      title: 'electromenager',
      type: 'soft',
      subLabels: [
        'ventilation',
        'rasoir_tondeuse_electrique',
        'volet_roulant',
        'telephone_vibreur',
        'aspirateur_menager',
        'bouilloire',
        'brosse_a_dent_electrique',
        'cafetiere',
        'frigo',
        'imprimante',
        'machine_a_laver',
        'micro_ondes',
        'seche_cheveux',
      ],
    },
    {
      title: 'eau',
      type: 'soft',
      subLabels: [
        'douche',
        'robinet',
        'chasse_eau',
        'bassine',
        'se_sert_de_l_eau',
      ],
    },
    {
      title: 'exterieur',
      type: 'soft',
      subLabels: ['feu_artifice', 'vehicule', 'vent', 'pluie', 'tonnerre'],
    },
    {
      title: 'horloge',
      type: 'soft',
    },
  ],
  [
    {
      title: 'toux',
      type: 'soft',
      subLabels: ['toux_grasse', 'toux_seche', 'toux_asthmatiforme'],
    },
    {
      title: 'respiration',
      type: 'soft',
      subLabels: ['respiration', 'soupir', 'reniflement'],
    },
    {
      title: 'ronflement',
      type: 'soft',
    },
    {
      title: 'difficulte_respiratoire',
      type: 'soft',
      subLabels: [
        'essoufflement',
        'respiration_asthmatiforme',
        'respiration_encombree',
        'respiration_eau_dans_les_poumons',
        'respiration_sifflante',
        'canule_bouchee',
      ],
    },
  ],
  [
    {
      title: 'animaux',
      type: 'soft',
      subLabels: ['oiseaux', 'chien', 'chat'],
    },
    {
      title: 'chariot',
      type: 'soft',
    },
    {
      title: 'vaisselle',
      type: 'soft',
    },
    {
      title: 'scratch',
      type: 'soft',
    },
    {
      title: 'trousseau_de_cle',
      type: 'soft',
    },
    {
      title: 'rideau',
      type: 'soft',
    },
    {
      title: 'fermeture_eclair',
      type: 'soft',
    },
  ],
  [
    {
      title: 'chute',
      type: 'soft',
      subLabels: ['chute_de_personne', 'objet_qui_tombe'],
    },
    {
      title: 'necessite_de_prevenir_as',
      type: 'soft',
    },
    {
      title: 'langue_etrangere',
      type: 'soft',
    },
    {
      title: 'parole_resident_intelligible',
      type: 'soft',
      subLabels: ['appel'],
    },
    {
      title: 'conversation',
      type: 'soft',
      subLabels: [
        'conversation_resident_as',
        'conversation_as_as',
        'conversation_resident_resident',
        'conversation_telephonique',
      ],
    },
  ],
  [
    {
      title: 'activite_humaine',
      type: 'soft',
      subLabels: ['activite_as', 'activite_resident'],
    },
    {
      title: 'emotions',
      type: 'soft',
      isNotLabel: true,
      subLabels: ['stress', 'joyeux'],
    },
    {
      title: 'sommeil_eveil',
      type: 'soft',
      isNotLabel: true,
      subLabels: ['resident_endormi', 'resident_eveille'],
    },
    {
      title: 'deux_personnes_ou_plus',
      type: 'soft',
    },
  ],
]

const LABELS_PAGINATION_DEMO: LabelsPagination = [
  [
    {
      title: 'aucun_son',
      type: 'soft',
    },
    {
      title: 'inaudible',
      type: 'soft',
    },
    {
      title: 'voix_as',
      type: 'soft',
    },
    {
      title: 'voix_resident',
      type: 'soft',
    },
    {
      title: 'television',
      type: 'soft',
    },
    {
      title: 'parole_loin',
      type: 'soft',
    },
    {
      title: 'son_lointain',
      type: 'soft',
    },
    {
      title: 'grincement',
      type: 'soft',
    },
    {
      title: 'choc',
      type: 'soft',
      subLabels: ['choc_violent', 'choc_leger'],
    },
    {
      title: 'bip_machine',
      type: 'soft',
    },
    {
      title: 'gemissement',
      type: 'soft',
    },
    {
      title: 'porte_entree',
      type: 'soft',
    },
    {
      title: 'porte_coulissante_tiroir',
      type: 'soft',
    },
    {
      title: 'bruit_de_pas',
      type: 'soft',
    },
    {
      title: 'tambourinage',
      type: 'soft',
    },
    {
      title: 'eternuement',
      type: 'soft',
    },
    {
      title: 'baillement',
      type: 'soft',
    },
    {
      title: 'crachat',
      type: 'soft',
    },
    {
      title: 'draps',
      type: 'soft',
    },
    {
      title: 'machines',
      type: 'soft',
    },
    {
      title: 'eau',
      type: 'soft',
    },
    {
      title: 'vent',
      type: 'soft',
    },
    {
      title: 'vaisselle',
      type: 'soft',
    },
    {
      title: 'horloge',
      type: 'soft',
    },
    {
      title: 'toux',
      type: 'soft',
    },
    {
      title: 'respiration',
      type: 'soft',
    },
    {
      title: 'ronflement',
      type: 'soft',
    },
    {
      title: 'essoufflement',
      type: 'soft',
    },
    {
      title: 'chute',
      type: 'soft',
      subLabels: ['chute_de_personne', 'objet_qui_tombe'],
    },
    {
      title: 'sommeil_eveil',
      type: 'soft',
      isNotLabel: true,
      subLabels: ['resident_endormi', 'resident_eveille'],
    },
    {
      title: 'deux_personnes_ou_plus',
      type: 'soft',
    },
  ],
]

const LABELS_PAGINATION_RESPIRATION: LabelsPagination = [
  [
    {
      title: 'respiration',
      type: 'soft',
      subLabels: ['respiration', 'reniflement', 'mouchage'],
    },
    {
      title: 'apnee',
      type: 'soft',
    },
    {
      title: 'signes_respiratoires',
      type: 'soft',
      subLabels: [
        'expiration_freinee',
        'sifflement_inspiration',
        'sifflement_expiration',
        'respiration_encombree',
        'expectoration',
        'rale_expiratoire',
        'ronflement',
      ],
    },
    {
      title: 'toux',
      type: 'soft',
      subLabels: [
        'toux_grasse',
        'toux_seche',
        'toux_sifflante',
        'raclement_gorge',
      ],
    },
  ],
  [
    {
      title: 'oxygenotherapie',
      type: 'soft',
    },
    {
      title: 'aerosol',
      type: 'soft',
      subLabels: ['aerosol_en_cours', 'aerosol_en_fin_de_dose', 'aerosol_vide'],
    },
    {
      title: 'vni',
      type: 'soft',
      subLabels: ['fuites_au_masque', 'vni_en_cours'],
    },
    { title: 'aspirateur_a_mucosites', type: 'soft' },
  ],
  [
    {
      title: 'suspicion_de_souffrance_respiratoire',
      type: 'soft',
    },
    {
      title: 'respiration_anormalement_rapide',
      type: 'soft',
    },
  ],
]

const LABELS_PAGINATION_KEYWORD: LabelsPagination = [
  [
    {
      title: 'parole_resident_intelligible',
      type: 'soft',
      subLabels: ['appel'],
    },
    {
      title: 'langue_etrangere',
      type: 'soft',
    },
  ],
]

const LABELS_PAGINATION_HARD_LABELS: LabelsPagination = [
  [
    {
      title: 'inspiration',
      type: 'hard',
      color: 'bg-purple-600',
    },
    {
      title: 'expiration',
      type: 'hard',
      color: 'bg-pink-600',
    },
  ],
]

export const labelsPaginationMap: Record<OntologyType, LabelsPagination> = {
  layer1: LABELS_PAGINATION,
  demo: LABELS_PAGINATION_DEMO,
  respiration: LABELS_PAGINATION_RESPIRATION,
  keyword: LABELS_PAGINATION_KEYWORD,
  unet: LABELS_PAGINATION_HARD_LABELS,
}

function getConfigFromTitle(
  labelsPagination: LabelsPagination,
  title: LabelKey,
) {
  const config = labelsPagination
    .flat()
    .find((config) => config.title === title)
  if (config === undefined)
    throw new Error(`config with title '${title}' not found`)
  return config
}

export function getLabelsGroupConfigFromTitle(
  labelsPagination: LabelsPagination,
  labelsGroupTitle: LabelKey,
) {
  const config = getConfigFromTitle(labelsPagination, labelsGroupTitle)
  if (config.type !== 'soft') {
    throw new Error(`'${labelsGroupTitle}' not matching any LabelsGroupConfig`)
  }
  return config
}

export function getHardLabelConfigFromTitle(
  labelsPagination: LabelsPagination,
  labelsGroupTitle: LabelKey,
) {
  const config = getConfigFromTitle(labelsPagination, labelsGroupTitle)
  if (config.type !== 'hard') {
    throw new Error(`'${labelsGroupTitle}' not matching any HardLabelConfig`)
  }
  return config
}
